<template>
  <q-table
    separator="cell"
    :data="localPayload"
    :columns="columns"
    :rows-per-page-options="[0]"
    row-key="id"
  >
    <template #top>
      <q-btn
        v-if="isCanEditPayload"
        color="primary"
        :disable="!isValid || isDisableAddBtn"
        label="Add credential"
        @click="addProperty(payload)"
      />
    </template>
    <template #body-cell-key="props">
      <q-td :props="props">
        <q-input
          dense
          borderless
          debounce="300"
          placeholder="Click to enter text"
          :disable="!isCanEditPayload"
          :value="props.row.key"
          :rules="rules"
          @input="update($event, props, 'key')"
        />
      </q-td>
    </template>
    <template #body-cell-value="props">
      <q-td :props="props">
        <q-input
          dense
          borderless
          :disable="!isCanEditPayload"
          debounce="300"
          placeholder="Click to enter text"
          :value="props.row.value"
          @input="update($event, props, 'value')"
        />
      </q-td>
    </template>
    <template #body-cell-action="props">
      <q-td :props="props">
        <q-btn
          flat
          :disable="!isCanEditPayload"
          fab-mini
          icon="mdi-delete-empty-outline"
          @click="removeItem(payload, props.row.id)"
        />
      </q-td>
    </template>
  </q-table>
</template>

<script>
import { columns } from './config';
import { ROLES } from '@/shared/constants';
import withIsEditingForm from '@/shared/mixins/withIsEditingForm';
import { authUtils } from '@/shared/utils';
import { uid } from 'quasar';
import { mapGetters } from 'vuex';

export default {
  mixins: [withIsEditingForm()],
  props: {
    payload: {
      type: Array,
      default: () => [],
    },
    isDisableAddBtn: {
      type: Boolean,
      default: () => false,
    },
  },
  data: () => ({
    localPayload: [],
    rules: [(val) => !!val || 'Value must be not empty.'],
  }),
  watch: {
    payload(val) {
      this.localPayload = val;
    },
  },
  computed: {
    ...mapGetters({
      userPermissions: 'GET_USER_PERMISSIONS',
    }),

    isOperator: () => authUtils.getRoleByHierarchy() === ROLES.ROLE_OPERATOR,
    isValid() {
      const isIncludesNulls = this.localPayload
        .flatMap((el) => Object.values(el))
        .includes(null);

      return !isIncludesNulls;
    },
    columns() {
      if (!this.isCanEditPayload) {
        return columns.filter((el) => el.name !== 'action');
      }
      return columns;
    },
    isCanEditPayload: ({ $store }) =>
      !!$store.getters.GET_USER_PERMISSIONS?.allowEditRequisitePayload,
  },
  methods: {
    update(value, { rowIndex }, key) {
      this.localPayload[rowIndex][key] = value.trim();
      if (!Object.values(this.localPayload[rowIndex]).includes(null)) {
        this.$emit('update:payload', this.localPayload);
      }
    },

    addProperty(payload) {
      this.$emit('update:payload', [
        { key: null, value: '', id: uid() },
        ...payload,
      ]);
    },

    removeItem(payload, id) {
      this.$emit(
        'update:payload',
        payload.filter((el) => el.id !== id)
      );
    },
  },
};
</script>
