<template>
  <d-edit-card
    :key="$route.params.id"
    :id="$route.params.id"
    :fetch-function="loadPaymentRequisite"
    :entity.sync="form"
    prev-page="PaymentRequisitesList"
    :card-title="`Payment requisite ${isEditingForm ? 'updating' : 'creation'}`"
    remove-confirm-title="Are you sure you want to delete this payment requisite?"
    remove-success-title="Payment requisite successfully removed"
    :remove-function="paymentRequisitesController.removePaymentRequisite"
    redirect-after-creation="PaymentRequisitesEdit"
    :submit-function="submitForm"
    :submit-success-title="`Payment requisite ${
      isEditingForm ? 'updated' : 'created'
    }`"
    ref="form"
  >
    <template #default="{ data, loading }">
      <q-card-section class="col">
        <div class="row">
          <div
            class="col-xs-12 col-md-6 q-col-gutter-md"
            :class="$q.screen.lt.md ? 'q-mb-sm' : 'q-pr-md'"
          >
            <q-input
              :disable="!permissions || !permissions.allowCreateRequisites"
              :dense="$q.screen.lt.md"
              class="col-12"
              v-model.trim="data.name"
              outlined
              label="Name"
              hint="Required field*"
              :rules="[rules.notNullField]"
            />
            <q-input
              v-model.trim="data.weight"
              class="col-12"
              outlined
              type="number"
              label="Weight"
              hint="Required field*"
              :rules="[rules.weightValueRule]"
              :disable="!permissions || !permissions.allowEditRequisiteWeight"
              :dense="$q.screen.lt.md"
            />
            <d-user-select
              v-if="isNeedAgentControl"
              v-model="data.agent"
              label="Agent"
              class="col-12"
              hint="Required field*"
              :multiple="false"
              :dense="$q.screen.lt.md"
              :disable="isOperator"
              :options="agentsDictionary"
              :rules="[(val) => !!val || 'Please type value']"
            />
            <d-user-select
              v-model="data.users"
              label="Operators"
              class="col-12"
              :dense="$q.screen.lt.md"
              :options="operatorsAtAgent"
              v-if="!isOperator"
              use-input
            />
            <q-select
              v-model="data.refillMethodTypes"
              class="col-12"
              label="Refill method"
              clearable
              outlined
              multiple
              option-value="id"
              option-label="name"
              hint="Required field*"
              :options="refillMethodsDictionary"
              :dense="$q.screen.lt.md"
              :disable="
                !permissions || !permissions.allowEditRequisiteRefillMethod
              "
              :rules="[(val) => !!val || 'Please type value']"
            />
            <q-input
              v-model="minimumDepositFormatted"
              class="col-12"
              outlined
              type="number"
              clearable
              label="Minimum requisite limit"
              disable
              :dense="$q.screen.lt.md"
            />
            <q-toggle
              dense
              v-model.trim="data.active"
              checked-icon="mdi-check"
              color="green"
              size="xl"
              label="Status"
              unchecked-icon="mdi-close"
              :disable="!permissions || !permissions.allowSwitchRequisite"
            />
          </div>
          <div class="col-xs-12 col-md-6">
            <d-payment-requisites-payload-table
              :is-disable-add-btn="
                loading ||
                !permissions ||
                !permissions.allowEditRequisitePayload
              "
              :payload.sync="data.payload"
            />

            <d-payment-requisites-credentials-table
              :is-disable-add-btn="
                loading ||
                !permissions ||
                !permissions.allowEditRequisitePayload
              "
              :payload.sync="data.credentials"
              class="q-mt-md"
            />
          </div>
        </div>
      </q-card-section>
    </template>
    <template #actions="{ remove, data }">
      <d-edit-form-actions
        :class="$q.screen.lt.md && 'full-width'"
        :is-editing-form="isEditingForm"
        prev-page="PaymentRequisitesList"
        @remove="remove"
      />
      <q-btn
        :class="$q.screen.lt.md && 'q-mt-sm full-width'"
        v-if="isEditingForm && isCanFilters"
        :label="
          data.paymentRequisiteFilterId ? 'Update filters' : 'Create filters'
        "
        :to="
          data.paymentRequisiteFilterId
            ? 'filter-editor'
            : 'filter-editor-creation'
        "
      />
    </template>
  </d-edit-card>
</template>

<script>
import { columns, dictionariesApiMap, rules } from './config';
import { DEditFormActions } from '@/features/edit-form-actions';
import { DPaymentRequisitesCredentialsTable } from '@/features/payment-requisites-credentials-table';
import { DPaymentRequisitesPayloadTable } from '@/features/payment-requisites-payload-table';
import DUserSelect from '@/features/user-select';
import DEditCard from '@/layouts/edit-card';
import { paymentRequisitesController as apiController } from '@/shared/api';
import { ROLES } from '@/shared/constants';
import withIsEditingForm from '@/shared/mixins/withIsEditingForm';
import {
  arrayOfObjectsToFlatObject,
  authUtils,
  flatObjectToArrayOfObjects,
  notify,
} from '@/shared/utils';
import { uid } from 'quasar';
import { mapGetters } from 'vuex';

export default {
  components: {
    DEditCard,
    DUserSelect,
    DEditFormActions,
    DPaymentRequisitesPayloadTable,
    DPaymentRequisitesCredentialsTable,
  },
  mixins: [withIsEditingForm()],
  data: () => ({
    rules,
    columns,
    form: {
      active: true,
      weight: 0,
      name: null,
      payload: [],
      credentials: [],
      users: [],
      refillMethodTypes: [],
      agent: null,
      minimumDeposit: null,
    },
    usersDictionary: [],
    operatorsAtAgent: [],
    refillMethodsDictionary: [],
    paymentRequisitesController: apiController(authUtils.getRoleByHierarchy()),
  }),

  async created() {
    await this.loadDictionaries();
    if (authUtils.getRoleByHierarchy() === ROLES.ROLE_AGENT) {
      await this.loadOperatorsAtAgent();
    }
  },

  watch: {
    'form.agent': {
      deep: true,
      handler: async function (val) {
        if (val && !this.isOperator) {
          await this.loadOperatorsAtAgent(val.id);
        }
      },
    },
  },

  computed: {
    ...mapGetters({
      userPermissions: 'GET_USER_PERMISSIONS',
    }),

    isOperator: () => authUtils.getRoleByHierarchy() === ROLES.ROLE_OPERATOR,
    minimumDepositFormatted: {
      get() {
        return !this.form.minimumDeposit
          ? null
          : Math.trunc(this.form.minimumDeposit);
      },
      set(value) {
        this.form.minimumDeposit = value;
      },
    },
    isCanFilters: () => authUtils.checkRoles([ROLES.ROLE_ADMIN_FILTER]),
    isNeedAgentControl: () =>
      authUtils.checkRoles([ROLES.ROLE_ADMIN, ROLES.ROLE_SUPERVISOR]),
    agentsDictionary: ({ usersDictionary }) =>
      usersDictionary.filter((user) => user.roles.includes(ROLES.ROLE_AGENT)),
    isAgent: () => authUtils.getRoleByHierarchy() === ROLES.ROLE_AGENT,
    permissions: ({ $store }) => $store.getters.GET_USER_PERMISSIONS,
  },

  methods: {
    async loadPaymentRequisite(id) {
      let {
        data: { credentials, payload, ...data },
      } = await this.paymentRequisitesController.getPaymentRequisiteById(id);

      credentials = credentials || {};
      payload = payload || {};

      return {
        data: {
          ...data,
          credentials: flatObjectToArrayOfObjects(credentials).map((el) => ({
            id: uid(),
            ...el,
          })),
          payload: flatObjectToArrayOfObjects(payload).map((el) => ({
            id: uid(),
            ...el,
          })),
        },
      };
    },

    async loadOperatorsAtAgent(id) {
      try {
        this.$refs.form.setLoading(true);
        const { operatorsByAgent } = dictionariesApiMap(
          authUtils.getRoleByHierarchy()
        );

        const { data } = await operatorsByAgent(id);
        this.operatorsAtAgent = data;
      } catch (e) {
        notify.error(e.message);
        console.error(e);
      } finally {
        this.$refs.form.setLoading(false);
      }
    },

    async loadDictionaries() {
      try {
        const { refillMethods: refillMethodsFetch, users: usersFetch } =
          dictionariesApiMap(authUtils.getRoleByHierarchy());

        const [{ data: refillMethods }, { data: users } = {}] =
          await Promise.all([
            refillMethodsFetch(),
            (() => {
              if (!this.isOperator) {
                return usersFetch();
              }
            })(),
          ]);

        this.refillMethodsDictionary = refillMethods;
        this.usersDictionary = users || [];
      } catch (e) {
        notify.error(e.message);
        console.error(e);
      }
    },

    async submitForm({ payload, users, agent, credentials, ...data }) {
      const action = this.isEditingForm
        ? this.paymentRequisitesController.updatePaymentRequisite
        : this.paymentRequisitesController.createPaymentRequisite;

      try {
        return await action({
          ...data,
          ...(agent && {
            agent: {
              id: agent.id,
            },
          }),
          users: users?.map(({ id }) => ({ id })),
          payload: arrayOfObjectsToFlatObject(
            payload?.map((item) => {
              const mappedPayload = { ...item };

              delete mappedPayload.id;
              return mappedPayload;
            })
          ),
          credentials: arrayOfObjectsToFlatObject(
            credentials?.map((item) => {
              const mappedCredentials = { ...item };

              delete mappedCredentials.id;
              return mappedCredentials;
            })
          ),
        });
      } catch (e) {
        const bodyMessage = e?.response?.data?.message || e.message;
        throw { message: bodyMessage, error: e };
      }
    },
  },
};
</script>
