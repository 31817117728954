import { refillMethodController, usersController } from '@/shared/api';

export const dictionariesApiMap = (role) => ({
  refillMethods: () => refillMethodController(role).getRefillMethods(),
  users: () => usersController(role).getUsers(),
  operatorsByAgent: (id) => usersController(role).getOperatorsByAgent(id),
});

export const columns = [
  {
    name: 'key',
    field: 'key',
    label: 'Key',
    align: 'center',
  },
  {
    name: 'value',
    field: 'value',
    label: 'Value',
    align: 'center',
  },
  {
    name: 'action',
    field: 'action',
    label: '',
    align: 'center',
    headerStyle: 'width: 100px',
  },
];

export const rules = {
  notNullField: (val) => (val && val.length > 0) || 'Please type value',
  weightValueRule: (val) => val >= 1 || 'Invalid value',
  minimumDepositRule: (val) => +val >= 0 || 'The value must be at least zero',
};
